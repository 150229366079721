import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Location from 'components/layout/Posts/Location'
import Tiles from 'components/layout/Posts/PostsTiles2'
import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Wiadomości',
      link: '/wiadomosci/',
    },
    {
      label: 'Media o nas',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Wiadomości',
      link: '/wiadomosci/',
    },
    {
      label: 'Media o nas',
    },
  ],
}

const MediaONasPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.aktualnosci
  // const PAGE_SEO = data?.wpPage?.seo

  const GALLERY =
    React.useMemo(
      () =>
        data?.allWpAcrtile?.nodes?.map((article) => ({
          img: article?.artykul?.articleArticleImg?.localFile?.childImageSharp
            ?.gatsbyImageData,
          alt: article?.artykul?.articleArticleImg?.altText,
          link: `/wiadomosci/media-o-nas/${article.slug}/`,
          badge1Text: '',
          badge2Text: article?.title,
        })),
      []
    ) ?? []

  return (
    <Layout>
      <SEO
        title="Media o nas - Gatigo"
        description="Media o nas - Gatigo"
        // ogTitle={PAGE_SEO.opengraphTitle}
        // ogDescription={PAGE_SEO.opengraphDescription}
        // ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        // twitterTitle={PAGE_SEO.twitterTitle}
        // twitterDescription={PAGE_SEO.twitterDescription}
        // twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={PAGE?.heroImg?.localFile?.childImageSharp?.gatsbyImageData}
        imgDesktop={PAGE?.heroImg?.localFile?.childImageSharp?.gatsbyImageData}
        headingMobile="Media o nas"
        headingDesktop="Media o nas"
        breadcrumbs={breadcrumbs}
      />
      <Location label="Media o nas" />
      <Tiles heading="Media o nas" gallery={GALLERY} />
    </Layout>
  )
}

export default MediaONasPage

export const query = graphql`
  query MediaONasPage {
    wpPage(slug: { regex: "/aktualnosci/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      title
      aktualnosci {
        heroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    allWpAcrtile(
      filter: { artykul: { articleArticleTyp: { eq: "media" } } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        title
        slug
        artykul {
          articleArticleTyp
          articleArticleImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [JPG, AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`
